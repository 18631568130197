import React, { Fragment } from "react";

const ServiceSidebar = () => {
  return (
    <Fragment>
      <aside className="widget categories">
        <h3 className="widget_title black-text">Үйлчилгээ</h3>
        <div className="meipaly_categorie_widget">
          <ul>
            <li>
              <a href="#">ПРОГРАММ</a>
            </li>
            <li>
              <a href="#">ТЕХНИК ЗАСВАР</a>
            </li>
            <li>
              <a href="#">БАРАА БҮТЭЭГДЭХҮҮН</a>
            </li>
          </ul>
        </div>
      </aside>
      <aside className="widget categories">
        <div className="meipaly_services_help">
          <h4>Бидэнтэй холбоо барих</h4>
          <h2>+976 94110121</h2>
        </div>
      </aside>
    </Fragment>
  );
};

export default ServiceSidebar;
