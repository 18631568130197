import React from "react";
import serviceS1 from "@/images/s1.jpg";
import serviceS2 from "@/images/s2.jpg";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <h2>Програмын үйлчилгээ</h2>
      <div className="row gaping">
        <div className="col-lg-12 col-sm-12 col-md-12">
          <ul>
            <li>
              <i className="fa fa-check-square"></i>•	Архив дараа он vvсгэх 
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Дансны төлөвлөгөөнд дэд данс нэмэх гүйлгээнд тохируулах-1ш данс
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Цалингийн дансны тохиргоог хийх
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Цалингийн нэмэгдэл суутгалуудын тохиргоо
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Цалингийн бичилтийн өглөг авлагын тохируулах бичилт хийх /1 сар/
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Нийгмийн даатгалын тайлан тохируулах
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	ХХОАТатварын тайлан тааруулах
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Өглөг авлагын үлдэгдэл тааруулах-/1 сар
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Төлөвлөгөөний хуваарь /бичлэг давхардах/
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Сарын мэдээ тохируулах/1 сар/
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Төсвийн гүйцэтгэлийн тайлан гаргах
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Мөнгөн кассын тайлан гаргах
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Шалгах баланстай үр дүнгийн тайлан тохируулах
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Шалгах баланстай мөнгөн гүйлгээ тайлан тохируулах
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Шалгах баланстай өмчийн өөрчлөл тайлан тохируулах
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Шалгах баланстай байдлын тайлан тохируулах
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Элэгдэлийн дансны тохиргоо 
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Үндсэн хөрөнгөтэй холбоотой гүйлгээнүүдийг засварлах /1 сар/
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Шалгах баланстай үндсэн хөрөнгийн тодруулга тааруулах
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Шалгах баланстай бараа материалын тодруулга тааруулах
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Шалгах баланстай өглөгийн тодруулга тайлан тааруулах
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Шалгах баланстай авлагын тодруулга тайлан тааруулах
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Гүйлгээ шивэхэд тусалж тайлан баланс гаргах /Улирлын ажил гүйлгээний тоо 300-аас ихгүй/
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Гүйлгээтэй баазын эх, үлд-ийг өмнөх онтой тохируул
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Гүйлгээний данс солих
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Гүйлгээтэй Материалын данс солих
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Excel-ээс үлдэгдэл татуулах
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	1,2,3-р хагасын тохиргоог хийх
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Балансыг Excel-  ийн томъёогоор шалгах
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Програм шилжүүлж суулгах
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Татварын тайлан шинэчилэлт
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	ТТ-11-4 тайлан шинэчилэлт
            </li><br />
            <li>
              <i className="fa fa-check-square"></i>•	Цалингийн тодорхойлолт шинэчилэлт
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailsContent;
